<template>
</template>

<script>
export default {
  name: "App",
}
</script>

<style>
  html, body {
    height: 100%;
  }

  body {
    top: 0px !important;
  }

  *, html {
    font-family: 'Prompt';
  }
</style>


<style lang="scss">
  @import "~bulma/sass/utilities/_all";
  // $primary: #2779A7;
  // $primary: rgb(237, 176, 167);
  $primary: #25A9DC;
  $primary-invert: findColorInvert($primary);
  $success: #00AD9B;
  $success-invert: findColorInvert($success);
  $danger: #DF6C4F;
  $warning: #F9BA46;
  $warning-invert: findColorInvert($warning);
  // $danger: #FF4A64;
  $danger-invert: findColorInvert($danger);
  $grey: #F6F2EF;
  $grey-invert: findColorInvert($grey);
  $colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "grey": ($grey, $grey-invert),
  );
  $link: $primary;
  $link-invert: $primary-invert;
  $link-focus-border: $primary;
  $body-background-color: #ECEDEF;
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
</style>