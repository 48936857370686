require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import Vue from 'vue/dist/vue.esm'
import '../../assets/css/font.css'
import App from 'packs/app.vue'
import axios from 'axios';
import moment from 'moment';
import VueAnalytics from 'vue-analytics'

Vue.component('app', App)
Vue.component('inbox', () => import('../packs/pages/inbox.vue'))
Vue.component('inbox2', () => import('../packs/pages/inbox2.vue'))
Vue.component('products', () => import('../packs/pages/products.vue'))
Vue.component('editPage', () => import('../packs/pages/edit.vue'))
Vue.component('lives', () => import('../packs/lives/liveIndex.vue'))
Vue.component('liveHistories', () => import('../packs/lives/histories.vue'))
Vue.component('manageLive', () => import('../packs/lives/manage.vue'))
Vue.component('liveOrders', () => import('../packs/lives/orders.vue'))
Vue.component('liveOverlay', () => import('../packs/lives/overlay.vue'))
Vue.component('liveQueue', () => import('../packs/lives/queue.vue'))
Vue.component('orderTracking', () => import('../packs/orders/orderTracking.vue'))
Vue.component('orderIndex', () => import('../packs/orders/orderIndex.vue'))
Vue.component('bannedCustomers', () => import('../packs/pages/bannedCustomers.vue'))
Vue.component('bots', () => import('../packs/pages/bots.vue'))
Vue.component('liveProductQueue', () => import('../packs/lives/productQueue.vue'))

import Buefy from 'buefy';

Vue.use(Buefy, { defaultIconPack: 'mdi', materialDesignIcons: true, defaultDateFormatter: (date) => moment(date).format("DD/MM/YYYY") });
Vue.use(VueAnalytics, {
  id: 'UA-67798885-3',
  autoTracking: {
    screenview: true
  }
})

Vue.filter('truncate', (value, length) => {
  if (value != null) {
    return value.length > length ? value.substr(0, length) + '...' : value
  } else {
    return ""
  }
})

Vue.filter('formatNumber', (val) => {
  return Number(val).toLocaleString(undefined, { 
    minimumFractionDigits: 2, maximumFractionDigits: 2 
  })
})

Vue.filter('formatTime', (time) => {
  let theTime = moment(time).utcOffset('+0700')
  let now = moment()
  if (now.format('DD/MM/YYYY') == theTime.format('DD/MM/YYYY')) {
    return theTime.format('HH:mm [น.]')
  } else {
    return theTime.format('DD/MM HH:mm [น.]')
  }
})

Vue.filter('showTime', (time) => {
  return moment(time).format('DD/MM/YYYY')
})

document.addEventListener('DOMContentLoaded', () => {
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  axios.defaults.headers.common['Accept'] = 'application/json'

  const app = new Vue({
    data: {
      name: 'App'
    },
  }).$mount('#vue')

  const app2 = new Vue({
    data: {
      name: 'App',
      loginModalActive: false
    },
  }).$mount('#navbar')
})

require("@rails/actiontext")
require("trix")